<template>
  <div class="edit w-100 h-100 d-flex flex-column align-items-center">
    <base-header
      :title="$t('基本資料')"
      :is-title-bold="true"
      :left-arrow="true"
      :back-on-click-left="true"
      :breadcrumb="breadcrumb"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
    ></base-header>
    <loading-wrapper v-if="isPageLoading"></loading-wrapper>
    <div class="edit-container w-100 scroll-bar">
      <div class="edit-wrap mx-auto d-flex">
        <div class="main-content">
          <div
            v-if="!$device.isMobile"
            class="title text-20 font-weight-bold mb-4"
          >
            {{ $t('基本資料') }}
          </div>
          <Info
            :nick-name.sync="nickName"
            @unaved-info="isModalMessageBoxShow = true"
            @update:nickName="updateNickName"
          ></Info>
        </div>
      </div>
    </div>
    <modal-message-box
      v-if="isModalMessageBoxShow"
      :result-content="[$t('你還沒有保存更改'), $t('確定要離開？')]"
      :buttons="messageBoxButtons"
      :has-btn-close="true"
      @on-item-click="onModalMessageBoxClick"
      @close="closeMessageBox"
    >
    </modal-message-box>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import Info from '@/components/Personal/Info.vue';
import ModalMessageBox from '@/components/Modal/ModalMessageBox.vue';

import BackEvent from '@/lib/base/backEvent.js';

export default {
  components: {
    BaseHeader,
    LoadingWrapper,
    Info,
    ModalMessageBox,
  },
  data() {
    return {
      isPageLoading: false,
      isModalMessageBoxShow: false,
      nickName: '',
      breadcrumb: [
        {
          name: this.$t('個人'),
          enable: true,
          router: 'personal',
        },
        {
          name: this.$t('基本資料'),
          enable: true,
          router: 'personal-edit',
        },
      ],
    };
  },
  computed: {
    userData() {
      return this.$store.getters['user/userData'];
    },
    messageBoxButtons() {
      return [
        {
          variant: this.$isJungo ? 'primary' : 'secondary',
          value: 'cancle',
          content: '取消',
        },
        {
          variant: this.$isJungo ? 'secondary' : 'primary',
          value: 'confirm',
          content: '確定',
        },
      ];
    },
  },
  methods: {
    async onModalMessageBoxClick(value) {
      BackEvent.popEvent();
      if (value === 'confirm') {
        return this.$router.push({name: 'personal'});
      }
      this.isModalMessageBoxShow = false;
    },
    closeMessageBox() {
      BackEvent.popEvent();
      this.isModalMessageBoxShow = false;
    },
    updateNickName(nickName) {
      this.nickName = nickName;
    },
  },
  beforeRouteLeave(to, from, next) {
    this.nickName && !this.isModalMessageBoxShow
      ? (this.isModalMessageBoxShow = true)
      : next();
  },
};
</script>

<style lang="scss" scoped>
.edit {
  overflow: overlay;
}
.edit-container {
  padding: 0px;
  .main-content {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .edit-container {
    padding: 40px 24px 0px;
    height: calc(100vh - 80px);
    overflow: overlay;
    .main-content {
      flex: 1 0 0;
      .title {
        height: 54px;
        line-height: 54px;
        border-bottom: 1px solid #dcdfe5;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .edit-container {
    .edit-wrap {
      max-width: 1140px;
    }
  }
}
</style>
