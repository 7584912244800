<template>
  <div class="info py-4 p-md-4">
    <div
      class="avatar-container d-flex justify-content-center justify-content-md-start align-items-center m-0 mb-3"
    >
      <img
        class="rounded-circle brightness"
        :srcset="
          require(`@/assets/img/personal/avatar/${
            userData.avatar || '0000'
          }.png?srcset`)
        "
        @click="editAvatar"
      />
      <i class="icon-Edit" @click="editAvatar"></i>
    </div>
    <div class="personal-list">
      <b-form @submit.prevent="onSubmit">
        <b-form-group label-for="nickName" class="nickName-input">
          <label for="nickName" class="label"
            >{{ $t('暱稱') }}<span v-if="$device.isMobile">：</span></label
          >
          <b-form-input
            id="nickName"
            ref="nickName"
            v-model="$v.nickName.$model"
            name="nickName"
            aria-describedby="input-nickName-feedback"
            type="text"
            :placeholder="userData.nickName"
            :state="isNickNameValid"
            @input="nickNameInputHandler"
          ></b-form-input>
          <b-form-invalid-feedback
            id="input-nickName-feedback"
            :state="isNickNameValid"
            class="mt-0"
          >
            <div v-if="!$v.nickName.required" class="error">
              {{ $t('*請輸入暱稱') }}
            </div>
            <div v-else-if="backendError">{{ backendError }}</div>
          </b-form-invalid-feedback>
        </b-form-group>
        <button
          type="submit"
          class="btn btn-primary"
          size="md"
          @click="onSubmit"
        >
          {{ $t('儲存') }}
        </button>
      </b-form>
    </div>
    <modal-wrapper
      v-if="isAvatarEditing"
      :is-modal-wrapper-show="isAvatarEditing"
      :is-show-transition="false"
      :click-to-close="true"
      @close="closeEdit"
    >
      <edit-personal-info @close="closeEdit" @click.native.stop="">
      </edit-personal-info>
    </modal-wrapper>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import userService from '@/services/user';

export default {
  components: {
    ModalWrapper: () => import('@/components/Base/ModalWrapper.vue'),
    EditPersonalInfo: () =>
      import('@/components/Personal/EditPersonalInfo.vue'),
  },
  props: {
    parentNickName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      nickName: '',
      isNickNameValid: null,
      isAvatarEditing: false,
    };
  },
  validations: {
    nickName: {required},
  },
  computed: {
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
  },
  watch: {
    nickName(newValue) {
      this.$emit('update:nickName', newValue);
    },
  },
  methods: {
    nickNameInputHandler() {
      this.isNickNameValid = this.nickName ? null : false;
      this.backendError = '';
    },
    async onSubmit() {
      if (this.isLoading) return;
      this.$v.$touch();
      this.isNickNameValid = null;
      if (this.$v.$invalid) {
        this.isNickNameValid = false;
        this.$refs.nickName.focus();
      } else {
        await this.submitUpdate();
      }
    },
    async submitUpdate() {
      try {
        this.$store.commit('env/setIsLoading', true);

        await userService.updateUser({
          nickName: this.nickName,
          isActive: true,
        });
        await this.$store.dispatch('user/loginWithJwt');
        this.nickName = '';
        await this.$nextTick();
        this.$Message.success(this.$t('儲存成功') + '！');
        return this.$router.push({name: 'personal'});
      } catch (error) {
        this.isNickNameValid = false;
        if (error.response.data.message === 'Incorrect nickName!') {
          this.backendError = '*' + this.$t('該名稱不得使用');
        } else {
          this.backendError = '*' + this.$t('個人資訊更改失敗');
        }
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    onItemClick(eventName) {
      return this.$router.push({name: eventName});
    },
    editAvatar() {
      if (this.$device.isMobile) {
        return this.$router.push({name: 'avatar'});
      } else {
        this.isAvatarEditing = true;
      }
    },
    closeEdit() {
      this.isAvatarEditing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  background: white;
  box-shadow: 0px 2px 7px 0px #c7c7c74d;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: auto;
    border-radius: 10px;
  }
}
.avatar-container {
  margin-left: 16px;
  position: relative;
  @media screen and (min-width: 768px) {
    margin-left: 14px;
  }
  img {
    width: 100px;
    height: 100px;
    cursor: pointer;
    &.brightness {
      filter: brightness(0.5);
      -webkit-filter: brightness(0.5);
    }
  }
  .icon-Edit {
    color: white;
    font-size: 32px;
    position: absolute;
    left: calc(50% - 18px);
    cursor: pointer;
    @media screen and (min-width: 768px) {
      left: calc(50px - 18px);
    }
  }
}
.personal-list {
  padding: 0 24px;
  @media screen and (min-width: 768px) {
    padding: 0;
  }
  .error {
    margin-top: 4px;
    height: 16px;
    color: $negative;
    font-size: 12px;
    text-align: start;
  }
  .form-control {
    font-size: 14px;
  }
  .nickName-input {
    .label {
      display: inline-block;
      @media screen and (min-width: 768px) {
        display: block;
      }
    }
    #nickName {
      display: inline-block;
      width: calc(100% - 50px);
      @media screen and (min-width: 768px) {
        display: block;
        width: 100%;
      }
    }
  }
  .btn-primary {
    width: 100%;
    @media screen and (min-width: 768px) {
      width: 240px;
    }
  }
}
</style>
